<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
        <el-button v-if="showInit && form_name!=3" type="primary" icon="el-icon-refresh" size="small"
          @click="init()">初始化配置项</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>绩效考核</el-breadcrumb-item>
          <el-breadcrumb-item>绩效考核配置</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-tabs v-model="form_name" type="card" @tab-click="handleClick">

        <el-tab-pane label="教师考核配置" name="2"></el-tab-pane>
        <el-tab-pane label="学生考核配置" name="1"></el-tab-pane>
        <!-- <el-tab-pane label="各系部单独管理考核" name="3"></el-tab-pane> -->
      </el-tabs>

      <div v-if="form_name!=3">
        <div style="width: 170px; float: left">
          <el-tag>考核总分({{ totalScore }}/100)</el-tag>
          <span style="margin: 0 10px">＝</span>
        </div>
        <div style="margin-left: 170px">
          <div v-for="(tag, i) in columnList" :key="i" style="display: inline-block; margin-bottom: 10px">
            <el-tag :type="tag.enabled?'success':'info'" style="cursor:pointer" @click="showInfo(tag)">{{
              tag.appraisal_name }}：{{ tag.appraisal_score }}分<span v-if="tag.num_desc">（{{ tag.num_desc
                }}）</span></el-tag>
            <span style="margin: 0 10px" v-if="i < columnList.length - 1">+</span>
          </div>
        </div>


        <el-form :model="firstRow" ref="ruleForm" label-width="120px">
          <div style="text-align: right; color: orangered; padding: 10px">
            提示，点击项目可编辑考核项,考核总分数必须等于100分；（此处教师请勿改动，如需修改请联系管理员！）
          </div>
        </el-form>
      </div>
      <!-- <div style="text-align: right; padding-right: 50px">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="addNew()"
          >添加项</el-button
        >

        <el-button type="primary" icon="el-icon-check" size="small" @click="saveForm()"
          >保存</el-button
        >
      </div> -->

      <el-dialog title="配置项" :visible.sync="dialogShow" :close-on-click-modal="false" :modal-append-to-body="true"
        :destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
        <div style="padding-right: 50px">
          <el-form :model="EditItem" ref="EditItem" label-width="120px">
            <el-form-item label="考核项名称">
              <el-input v-model="EditItem.appraisal_name" placeholder="考核项名称"></el-input>

            </el-form-item>
            <el-form-item label="考核项代码">
              <el-input v-model="EditItem.appraisal_code" readonly placeholder="考核项代码">></el-input>
              <div style="color: orangered; font-size: 12px">此项不可修改</div>
            </el-form-item>
            <el-form-item label="考核项分数">
              <el-input v-model="EditItem.appraisal_score" type="number" placeholder="填写分数整数"></el-input>
            </el-form-item>
            <el-form-item label="考核项参数">
              <el-input v-model="EditItem.appraisal_num"></el-input>
              <div style="color: orangered; font-size: 12px">
                设置考核次数，如周报需要填写4次才得分（注：部分参数需要配合【系统参数】一起修改才生效！
              </div>
            </el-form-item>
            <el-form-item label="参数描述">
              <el-input v-model="EditItem.num_desc"></el-input>
              <div style="color: orangered; font-size: 12px">
                对考核项进行简单表述，会显示在公式里
              </div>
            </el-form-item>
            <el-form-item v-if="$store.state.pmid == 226 && EditItem.appraisal_code == 'xf'" label="增加分数">
              <el-input v-model="EditItem.inc_score"></el-input>
              <div style="color: orangered; font-size: 12px">
                考核次数每超过一次，增加分数
              </div>
            </el-form-item>
            <el-form-item label="是否启用">

              <el-radio-group v-model="EditItem.enabled">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
            </el-form-item>

            <div style="text-align: right; padding: 10px">
              <el-button type="primary" icon="el-icon-check" @click="saveForm">保存</el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import util from "../../utils/util";
export default {
  data() {
    return {
      dialogShow: false,
      showInit: false,
      columnList: [],
      form_name: "2",
      firstRow: {
        appraisal_name: "", //考核项名称 (必传)
        appraisal_code: "", //考核项Code (必传)
        appraisal_score: "", //考核项总分数 (必传)
        appraisal_type: "", //考核项类型 1:学生 | 2:老师 (必传)
        appraisal_num: "", //考核值 例如:巡访一个月几次
      },
      rowidx: -1,
      totalScore: 0,
      EditItem: {},
    };
  },
  mounted() {
    this.getColumn();
  },
  methods: {
    handleClick() {
      this.columnList = [];
      this.getColumn();
    },
    addNew() {
      this.columnList.push({
        ...this.firstRow,
      });
    },
    getColumn() {
      this.$http
        .post("/api/performance_appraisal_select", { appraisal_type: this.form_name })
        .then((res) => {
          if (res.data.length == 0) {
            this.showInit = true;
            this.columnList.push({
              ...this.firstRow,
            });
          } else {
            this.showInit = false;
            res.data = res.data.sort((a, b) => {
              return b.appraisal_score - a.appraisal_score;
            });
            this.columnList = res.data;
            this.totalScore = 0;
            for (let item of this.columnList) {
              if (item.enabled) {
                this.totalScore += item.appraisal_score;
              }
            }
          }
        });
    },
    showInfo(e){
      this.EditItem = e;
      this.dialogShow = true
    },
    removeIdx(idx) {
      let item = this.columnList.splice(idx, 1);

      if (item[0].id) {
        this.$http
          .post("/api/performance_appraisal_delete", { id: item[0].id })
          .then((res) => {});
      }
    },

    formAdd() {
      this.dialogForm = true;
      this.modalTitle = "添加";
      this.formData = {};
    },
    saveForm() {
      this.$http.post("/api/performance_appraisal_edit", this.EditItem).then((res) => {
          this.getColumn();
          this.$message.success("保存成功");
          this.dialogShow = false
        });
      
    },
    init() {
      this.$http.post("/api/performace_config_init").then((res) => {
        this.$message.success("初始化成功");
        this.getColumn();
      });
    },
    closeDialog() {},
  },
};
</script>
